import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CasefinalresultInput, CasesService, ConsultCaseComments, OperandosService, Rates } from '@app/core/services/api';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DOCTOR } from '../utils/commonMethods';
@Component({
  selector: 'app-detail-case-tarifas-table',
  templateUrl: './detail-case-tarifas-table.component.html',
  styleUrls: ['./detail-case-tarifas-table.component.scss']
})
export class DetailCaseTarifasTableComponent implements OnInit {
  @Input() canEdit = false;
  @Input() idCase: string;
  _finalComments;
  @Output() updateDataSource: EventEmitter<any> = new EventEmitter();
  @Output() saveChange: EventEmitter<boolean> = new EventEmitter(false,);
  decisionForm: FormGroup;
  newData: any[] = [];
  editMode = false;
  editTypeCancel = false;
  searchControl: FormControl;
  value = '';
  _data;
  comment = '';
  commentValue = '';
  operands: string[];
  subscription: Subscription;
  previousDesicionBeforeEdit;
  constructor(private authSrv: AuthenticationService, private translateSrv: TranslateService, private casesSrv: CasesService, private loaderSrv: SpinnerService, private operandosSrv: OperandosService,) { }

  @Input() set dataSource(value: any) {
    this._data = value;
    if (this._data && this._data.values.length) {
      this.showDecision();
    }
  }

  @Input() set finalComments(value: [{}]) {
    this._finalComments = value;

    if (this._finalComments && this._data.values.length) {
      this.showDecision();
    }

  }

  ngOnInit() {
    this.searchControl = new FormControl('');
    this.decisionForm = new FormGroup({
      comment: new FormControl({ value: this.comment, disabled: true })
    });
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe({ next: (textFilter) => this.filterRisksFees(textFilter) });
    this.operandosSrv.operandosGet().subscribe({
      next: (data) => {
        // console.log(data);
        this.operands = [...data];
      },
      error: (error) => console.log(error)
    });
  }

  filterRisksFees(data: string): void {
    this.updateDataSource.emit(data);
  }

  hasCalc = (key: string): boolean => this._data.values.findIndex((item: { id: string | string[]; }) => item.id.includes(key)) > -1;

  isDoctorRole = () => this.authSrv.checkRolUser(DOCTOR);

  edit() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.previousDesicionBeforeEdit = Object.assign({}, this.decisionForm.value);
      this.decisionForm.enable();
      this.decisionForm.controls.comment.disable();
      this.subscription = this.decisionForm.valueChanges.subscribe((data) => {
        for (const keyForm of Object.keys(data)) {
          if (keyForm !== 'comment') {
            const res = this.newData.find(item => item.title.split('.')[2] === keyForm);
            if (res) {
              res.value = data[keyForm];
            }
          } else {
            this.comment = data[keyForm];
          }
        }
        if (!this.decisionForm.controls.comment.enabled) {
          this.decisionForm.controls.comment.enable();
          this.decisionForm.controls.comment.addValidators([Validators.minLength(10), Validators.required]);
          this.decisionForm.controls.comment.updateValueAndValidity();
        }

      });
    } else {
      if (this.editTypeCancel) {
        this.decisionForm.reset(this.previousDesicionBeforeEdit);
        this.editTypeCancel = false;
      }
      this.subscription.unsubscribe();
      this.decisionForm.disable();
    }
  }

  /**
   * This method emit to father component the order to save changes
   */
  saveChanges() {
    const userData: any = this.authSrv.decodeJwt(sessionStorage.getItem(this.authSrv.TOKEN));

    const comment: ConsultCaseComments = {
      comment: this.comment,
      rol: userData.rol,
      date: new Date().toISOString(),
      email: userData.email,
    };
    const decisionBody: CasefinalresultInput = {
      idCase: this.idCase,
      result: this.newData,
      comments: comment
    };
    if (this.decisionForm.valid) {
      this.casesSrv.casefinalresultPost(decisionBody).subscribe({
        next: (response) => {
          this.saveChange.emit(true);
          this.edit();
        }
      });

    } else {
      this.decisionForm.markAllAsTouched();
    }
  }

  cancelEdition() {
    this.updateDataSource.emit(false);
    this.editTypeCancel = true;
    this.edit();
  }

  deleteRow(id: string) {
    // console.log(id);
    this.findById(this._data.values, id, null);
  }

  findById(tree: any, nodeId: string, lastNode: { children: any[]; }) {
    for (const node of tree) {
      if (node.id === nodeId) {
        if (lastNode) {
          const index = lastNode.children.findIndex((item) => item.id === nodeId);
          lastNode.children.splice(index, 1);
        } else {
          const index = this._data.values.findIndex((item) => item.id === nodeId);
          this._data.values.splice(index, 1);
        }
        this.updateDataSource.emit(this._data);
      }
      if (node.children) {
        this.findById(node.children, nodeId, node);
      }
    }
  }
  changeData($event: Rates) {
    // console.log('changeData');
    this.updateDataSource.emit($event);
  }

  showDecision() {

    if (!this._finalComments) {
      this.comment = '';
    } else {
      this.comment = this._finalComments[this._finalComments.length - 1].comment ? this._finalComments[this._finalComments.length - 1].comment : '';
    }
    this.decisionForm.controls.comment.setValue(this.comment);
    this.newData = [];
    for (let index = 2; index < this._data.headerValues.length; index++) {
      const t = this._data.headerValues[index];
      let v: any;
      if (this._data.values.find((item) => item.id === 'finalResult') && this._data.values.find((item) => item.id === 'finalResult').values[2] !== undefined && this._data.values.find((item) => item.id === 'finalResult').values[2] !== '') {
        v = this._data.values.find((item: { id: string; }) => item.id === 'finalResult').values[index];
      } else {
        v = this._data.values.find((item: { id: string; }) => item.id === 'Resultado').values[index];
      }
      const control = new FormControl({ value: v, disabled: true }, Validators.required);
      this.decisionForm.addControl(t.split('.')[2], control);
      if (v) {
        this.newData.push({ title: t, value: v });
      }
    }

  }

  selectText(event: { target: { value: string; }; }) {
    this.comment = event.target.value;
    // console.log(event.target.value)
  }

  selectOption(event) {
    // console.log(event.target.value);
  }

}

