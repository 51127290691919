/**
 * servicios menus y contenido
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: csgj@gft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse20018 } from '../model/inlineResponse20018';
import { InlineResponse20019 } from '../model/inlineResponse20019';
import { Melanoma } from '../model/melanoma/melanoma';
import { MelanomaError } from '../model/melanoma/melanomaError';
import { Mensaje } from '../model/mensaje';
import { MetabolicoInput } from '../model/metabolic/metabolicoInput';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MetabolicoService {
  protected basePath = environment.apiUrl + environment.apiEnv;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * albuminuria metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public albuminuriaPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public albuminuriaPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public albuminuriaPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public albuminuriaPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling albuminuriaPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/albuminuria`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * colesterol metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public colesterolPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public colesterolPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public colesterolPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public colesterolPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling colesterolPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/colesterol`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Obtiene los combogfr
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public combogfrGet(observe?: 'body', reportProgress?: boolean): Observable<Array<InlineResponse20019>>;
  public combogfrGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InlineResponse20019>>>;
  public combogfrGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InlineResponse20019>>>;
  public combogfrGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<InlineResponse20019>>('get', `${this.basePath}/combogfr`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * creatinina metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public creatininePost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public creatininePost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public creatininePost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public creatininePost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling creatininePost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/creatinine`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * HDL metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public hDLPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public hDLPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public hDLPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public hDLPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling hDLPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/HDL`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Obtiene los valores de referencia
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public hepaticoGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20018>;
  public hepaticoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20018>>;
  public hepaticoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20018>>;
  public hepaticoGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20018>('get', `${this.basePath}/hepatico`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * imc metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public imcPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public imcPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public imcPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public imcPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling imcPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/imc`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * indiceAterogenico metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public indiceAterogenicoPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public indiceAterogenicoPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public indiceAterogenicoPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public indiceAterogenicoPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling indiceAterogenicoPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/indiceAterogenico`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * LDL metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public lDLPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public lDLPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public lDLPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public lDLPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling lDLPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/LDL`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public metabolicoPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Melanoma>;
  public metabolicoPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Melanoma>>;
  public metabolicoPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Melanoma>>;
  public metabolicoPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling metabolicoPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Melanoma>('post', `${this.basePath}/metabolico`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * nivelGFR metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nivelGFRPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public nivelGFRPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public nivelGFRPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public nivelGFRPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling nivelGFRPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/nivelGFR`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * proteinuria metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public proteinuriaPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public proteinuriaPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public proteinuriaPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public proteinuriaPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling proteinuriaPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/proteinuria`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * Obtiene los rangos de smoke
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public smokeGet(observe?: 'body', reportProgress?: boolean): Observable<Array<InlineResponse20019>>;
  public smokeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InlineResponse20019>>>;
  public smokeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InlineResponse20019>>>;
  public smokeGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<InlineResponse20019>>('get', `${this.basePath}/smoke`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * indiceAterogenico metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public trigliceridosPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public trigliceridosPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public trigliceridosPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public trigliceridosPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling trigliceridosPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/trigliceridos`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   *
   * VLDL metabolico
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public vLDLPost(body: MetabolicoInput, observe?: 'body', reportProgress?: boolean): Observable<Mensaje>;
  public vLDLPost(body: MetabolicoInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Mensaje>>;
  public vLDLPost(body: MetabolicoInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Mensaje>>;
  public vLDLPost(body: MetabolicoInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling vLDLPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Mensaje>('post', `${this.basePath}/VLDL`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
