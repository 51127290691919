import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export class CalcValidators {
  constructor(translateService: TranslateService) {}

  static age(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const age = moment().diff(control.value, 'years');
      return age >= min && age <= max ? null : { error: { key: 'ERROR.AGE', min, max } };
    };
  }

  static date(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const age = moment().diff(control.value, 'years');
      return age >= min && age <= max ? null : { error: { key: 'ERROR.AGE', min, max } };
    };
  }

  static height(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const heightMeasure: string = control.parent?.controls['heightMeasure'].value;
      if (heightMeasure === 'cm') {
        return control.value >= 100 && control.value <= 250 ? null : { error: 'ERROR.HEIGHT' };
      } else if (heightMeasure === 'm') {
        return control.value >= 1.0 && control.value <= 2.5 ? null : { error: 'ERROR.HEIGHT' };
      } else if (heightMeasure === 'ft') {
        return control.value >= 3.4 && control.value <= 8.2 ? null : { error: 'ERROR.HEIGHT' };
      } else {
        return { error: 'ERROR.MEASURE' };
      }
    };
  }

  static weight(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const weightMeasure: string = control.parent?.controls['weightMeasure'].value;
      if (weightMeasure === 'kg') {
        return control.value >= 35 && control.value <= 300 ? null : { error: 'ERROR.WEIGHT' };
      } else if (weightMeasure === 'lb') {
        return control.value >= 77 && control.value <= 661 ? null : { error: 'ERROR.WEIGHT' };
      } else {
        return { error: 'ERROR.MEASURE' };
      }
    };
  }

  static abdominalPerimeter(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === null || control.value === undefined || control.value === '') {
        return null;
      }
      if (measure === 'cm' || measure === 'in') {
        return control.value >= min && control.value <= max ? null : { error: { key: 'ERROR.ABDOMINAL-PERIMETER', min, max, measure } };
      } else {
        return { error: { key: 'ERROR.MEASURE' } };
      }
    };
  }

  static systolic(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value !== undefined && control.value !== '') {
        return control.value >= min && control.value <= max ? null : { error: { key: 'ERROR.SYSTOLIC', min, max } };
      } else {
        if (control.errors && control.errors.required) {
          return { error: { key: 'ERROR.REQUIRED' } };
        } else {
          return null;
        }
      }
    };
  }

  static diastolic(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value !== undefined && control.value !== '') {
        return control.value >= min && control.value <= max ? null : { error: { key: 'ERROR.DIASTOLIC', min, max } };
      } else {
        if (control.errors && control.errors.required) {
          return { error: { key: 'ERROR.REQUIRED' } };
        } else {
          return null;
        }
      }
    };
  }

  static colesterol(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.COLESTEROL', min, max, measure } };
  }

  static hdl(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.HDL', min, max, measure } };
  }

  static triglycerides(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.TRIGLYCERIDES', min, max, measure } };
  }

  static glucoseOrHBA1C(formGroup: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const glucose = formGroup.controls['glucose'].value;
      const HBA1C = formGroup.controls['HBA1C'].value;
      if (glucose || HBA1C) {
        return null;
      } else {
        return { error: 'ERROR.GLUCOSE-HBA1C' };
      }
    };
  }

  static glucose(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.GLUCOSE', min, max, measure } };
  }

  static hbA1c(min: number, max: number, measure: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.HBA1C', min, max, measure } };
  }

  static yearsSinceDiabetesDiagnosis(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.YEARS-SINCE-DIABETES-DIAGNOSIS', min, max } };
  }

  static liverValues(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.LIVER-VALUE', min, max } };
  }

  static creatinine(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const creatinineMeasure: string = control.parent?.controls['creatinineMeasure'].value;
      if (control.value === null || control.value === undefined || control.value === '') {
        return null;
      } else {
        return creatinineMeasure ? null : { error: { key: 'ERROR.MEASURE' } };
      }
    };
  }

  static uricAcid(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMinMaxOrNotInformed(control.value, min, max)
      ? null
      : { error: { key: 'ERROR.URIC-ACID', min, max } };
  }

  static moreZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => CalcValidators.checkMoreZero(control.value)
      ? null
      : { error: { key: 'ERROR.GENERIC' } };
  }

  private static checkMinMaxOrNotInformed(value: any, min: number, max: number) {
    if (value !== null && value !== undefined && value !== '') {
      return value >= min && value <= max;
    } else {
      return true;
    }
  };

  private static checkMoreZero(value: any) {
    if (value !== null && value !== undefined && value !== '') {
      return value >= 0;
    } else {
      return true;
    }
  };
}
