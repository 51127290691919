import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// @fwmovilidad/qb-lib-ng-components
import { QbComboBoxModule } from '@fwmovilidad/qb-lib-ng-components';

// Components across the app
import { MareselHeaderComponent } from './maresel-header/maresel-header.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { UserFabComponent } from './user-fab/user-fab.component';
import { MaterialModule } from './material/material.module';
import { MareselNavMenuComponent } from './maresel-nav-menu/maresel-nav-menu.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { MareselFooterComponent } from './maresel-footer/maresel-footer.component';
import { MareselHeaderLogoComponent } from './maresel-header-logo/maresel-header-logo.component';
import { MareselDynamicIntegrationComponent } from './maresel-dynamic-integration/maresel-dynamic-integration.component';
import { MareselHeaderStickyComponent } from './maresel-header-sticky/maresel-header-sticky.component';
import { DialogOverviewComponent } from '@shared/dialog-overview/dialog-overview.component';
import { ScrollEventsDirective } from '@app/core/directives/scroll-events/scroll-events.directive';
import { ModalTarifasComponent } from './modal-tarifas/modal-tarifas.component';
import { TarifasTableComponent } from './tarifas-table/tarifas-table.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { IonicModule } from '@ionic/angular';
import { MareselTableUsersComponent } from './maresel-table-users/maresel-table-users.component';
import { ModalDataUserComponent } from './modal-data-user/modal-data-user.component';
import { ModalSubscribeUserComponent } from './modal-subscribe-user/modal-subscribe-user.component';
import { ModalDynamicIntegrationComponent } from './modal-dynamic-integration/modal-dynamic-integration.component';
import { ModalNewCaseComponent } from './modal-new-case/modal-new-case.component';
import { EditTarifasTableComponent } from './edit-tarifas-table/edit-tarifas-table.component';
import { EditTarifasTableFormComponent } from './edit-tarifas-table-form/edit-tarifas-table-form.component';
import { ModifyTarifasComponent } from './modify-tarifas/modify-tarifas.component';
import { MareselInputAutocompleteCoberturasComponent } from './maresel-input-autocomplete-coberturas/maresel-input-autocomplete-coberturas.component';
import { BtnSavePdfComponent } from './btn-save-pdf/btn-save-pdf.component';
import { MelanomaCalcFormComponent } from './melanoma-calc-form/melanoma-calc-form.component';
import { MelanomaCalcResultComponent } from './melanoma-calc-result/melanoma-calc-result.component';
import { BreastCancerCalcFormComponent } from './breast-cancer-calc-form/breast-cancer-calc-form.component';
import { BreastCancerCalcResultComponent } from './breast-cancer-calc-result/breast-cancer-calc-result.component';
import { ModalDrugBankComponent } from './modal-drug-bank/modal-drug-bank.component';
import { DrugFinderSeekerComponent } from './drug-finder-seeker/drug-finder-seeker.component';
import { CaseDetailTooltipComponent } from './case-detail-tooltip/case-detail-tooltip.component';
import { WorkareaFilterComponent } from './workarea-filter/workarea-filter.component';
import { CommonFilterComponent } from './common-filter/common-filter.component';
import { NavTabFilterComponent } from './nav-tab-filter/nav-tab-filter.component';
import { ModalReopenCaseComponent } from './modal-reopen-case/modal-reopen-case.component';
import { DetailCaseHeaderComponent } from './detail-case-header/detail-case-header.component';
import { DetailCaseBasicDataComponent } from './detail-case-basic-data/detail-case-basic-data.component';
import { DetailCaseCommentComponent } from './detail-case-comment/detail-case-comment.component';
import { DetailCaseHeaderStickyComponent } from './detail-case-header-sticky/detail-case-header-sticky.component';
import { DetailCaseTarifasTableComponent } from './detail-case-tarifas-table/detail-case-tarifas-table.component';
import { MetabolicCalcFormComponent } from './metabolic-calc-form/metabolic-calc-form.component';
import { MetabolicCalcBloodPressureComponent } from './metabolic-calc-blood-pressure/metabolic-calc-blood-pressure.component';
import { TooltipInfoComponent } from './tooltip-info/tooltip-info.component';
import { MetabolicCalcLipidGlycemicProfileComponent } from './metabolic-calc-lipid-glycemic-profile/metabolic-calc-lipid-glycemic-profile.component';
import { MetabolicCalcLipidicProfileComponent } from './metabolic-calc-lipidic-profile/metabolic-calc-lipidic-profile.component';
import { MetabolicCalcGlycemicProfileComponent } from './metabolic-calc-glycemic-profile/metabolic-calc-glycemic-profile.component';
import { MedicationDetailComponent } from './medication-detail/medication-detail.component';
import { MetabolicCalcLiverProfileComponent } from './metabolic-calc-liver-profile/metabolic-calc-liver-profile.component';
import { MetabolicCalcKidneyProfileComponent } from './metabolic-calc-kidney-profile/metabolic-calc-kidney-profile.component';
import { MetabolicCalcResultsComponent } from './metabolic-calc-results/metabolic-calc-results.component';
import { MetabolicCalcResultsTreatmentComponent } from './metabolic-calc-results-treatment/metabolic-calc-results-treatment.component';
import { ModalSelectDoctorComponent } from './modal-select-doctor/modal-select-doctor.component';
import { DetailCasePrintComponent } from './detail-case-print/detail-case-print.component';
import { ModalSimilarPricingComponent } from './modal-similar-pricing/modal-similar-pricing.component';
import { MareselPaginatorComponent } from './maresel-paginator/maresel-paginator.component';
import { TableCasesComponent } from './table-cases/table-cases.component';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ContentSearchComponent } from './content-search/content-search.component';
import { CartMenuPopoverComponent } from './cart-menu-popover/cart-menu-popover.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { MareselInputAutocompleteComponent } from './maresel-input-autocomplete/maresel-input-autocomplete.component';
import { MareselInputAutocompleteKeyNameComponent } from './maresel-input-autocomplete-key-name/maresel-input-autocomplete-key-name.component';
import { ModalSelectCedenteComponent } from './modal-select-cedente/modal-select-cedente.component';
import { OptionsScrollDirective } from '@app/core/directives/options-scrolls/options-scroll.directive';
import { EmptyPipe } from '@app/core/pipes/empty.pipe';
import { ModalDetailDrugComponent } from './modal-detail-drug/modal-detail-drug.component';
import { SelectCurrenciesComponent } from './select-currencies/select-currencies.component';
import { SelectCountriesComponent } from './select-countries/select-countries.component';
import { SelectRisksComponent } from './select-risks/select-risks.component';
import { DurationSelectComponent } from './duration-select/duration-select.component';
import { SelectRatesComponent } from './select-rates/select-rates.component';
import { DetailCaseBasicPrintComponent } from './detail-case-basic-print/detail-case-basic-print.component';
import { DetailCaseTarifasPrintComponent } from './detail-case-tarifas-print/detail-case-tarifas-print.component';
import { NoCookiesNoticeComponent } from './no-cookies-notice/no-cookies-notice.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
export const MY_FORMATS_EN = {
  parse: {
    dateInput: 'MM-DD-YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const components = [
  ArticleItemComponent,
  BreastCancerCalcFormComponent,
  BreastCancerCalcResultComponent,
  BtnSavePdfComponent,
  CartMenuPopoverComponent,
  CaseDetailTooltipComponent,
  CommonFilterComponent,
  ContentSearchComponent,
  DetailCaseBasicDataComponent,
  DetailCaseBasicPrintComponent,
  DetailCaseCommentComponent,
  DetailCaseHeaderComponent,
  DetailCaseHeaderStickyComponent,
  DetailCasePrintComponent,
  DetailCaseTarifasPrintComponent,
  DetailCaseTarifasTableComponent,
  DialogOverviewComponent,
  DrugFinderSeekerComponent,
  DurationSelectComponent,
  EditTarifasTableComponent,
  EditTarifasTableFormComponent,
  EmptyPipe,
  GenericModalComponent,
  LanguageSelectorComponent,
  MareselDynamicIntegrationComponent,
  MareselFooterComponent,
  MareselHeaderComponent,
  MareselHeaderLogoComponent,
  MareselHeaderStickyComponent,
  MareselInputAutocompleteCoberturasComponent,
  MareselInputAutocompleteComponent,
  MareselInputAutocompleteKeyNameComponent,
  MareselNavMenuComponent,
  MareselPaginatorComponent,
  MareselPaginatorComponent,
  MareselTableUsersComponent,
  MedicationDetailComponent,
  MelanomaCalcFormComponent,
  MelanomaCalcResultComponent,
  MetabolicCalcBloodPressureComponent,
  MetabolicCalcFormComponent,
  MetabolicCalcGlycemicProfileComponent,
  MetabolicCalcKidneyProfileComponent,
  MetabolicCalcLipidGlycemicProfileComponent,
  MetabolicCalcLipidicProfileComponent,
  MetabolicCalcLiverProfileComponent,
  MetabolicCalcResultsComponent,
  MetabolicCalcResultsTreatmentComponent,
  ModalDataUserComponent,
  ModalDetailDrugComponent,
  ModalDrugBankComponent,
  ModalDynamicIntegrationComponent,
  ModalNewCaseComponent,
  ModalReopenCaseComponent,
  ModalSelectCedenteComponent,
  ModalSelectDoctorComponent,
  ModalSimilarPricingComponent,
  ModalSubscribeUserComponent,
  ModalTarifasComponent,
  ModifyTarifasComponent,
  NavTabFilterComponent,
  NoCookiesNoticeComponent,
  OptionsScrollDirective,
  ScrollEventsDirective,
  SelectCountriesComponent,
  SelectCurrenciesComponent,
  SelectRatesComponent,
  SelectRisksComponent,
  ShoppingCartComponent,
  TableCasesComponent,
  TarifasTableComponent,
  TooltipInfoComponent,
  UserFabComponent,
  WorkareaFilterComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule, MaterialModule, IonicModule],
  declarations: components,
  exports: [components, MaterialModule, TranslateModule, IonicModule],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: localStorage.getItem('langSelect') === 'en' ? MY_FORMATS_EN : MY_FORMATS }
  ]
})
export class SharedModule { }
