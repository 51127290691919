import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { CalcValidators } from '@app/core/directives/validators/calc-validators';
import { BreastCancerService } from '@app/core/services/api/api/breastcancer.service';
import { IBreastCancerForm } from '@app/core/services/api/model/breastCancer/breastCancerCalcForm.model';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { SpinnerService } from '@app/core/services/spinner/spinner.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { instanceOfError } from '../utils/commonMethods';
import { KI67, breastCancerTNM, etapaOEstado, her2, histologyTypeList, historicalGrade, metastesesOptions, molecularTypeList, nodalInvolvementOptions, rhre, rhrp, stages } from './breast-cancer-calc-inputs';
import { IKeyValue } from './breast-cancer-calc-interfaces';
import { FormUtilsService } from '@app/core/services/form-utils.service';

@Component({
  selector: 'app-breast-cancer-calc-form',
  templateUrl: './breast-cancer-calc-form.component.html',
  styleUrls: ['./breast-cancer-calc-form.component.scss'],
  providers: [FormGroupDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreastCancerCalcFormComponent implements OnInit {

  @ViewChild('treatmentTypeAccordion') accordion: MatAccordion;
  @Input() data: IBreastCancerForm | null;
  @Output() dataResult: EventEmitter<any> = new EventEmitter();

  showBirthDate: boolean = false;
  birthDate: string;
  maxDate: Date;

  basicDataForm: FormGroup;
  chemotherapyForm: FormGroup;
  collapsableForm: FormGroup;
  immunotherapyForm: FormGroup;
  histologyForm: FormGroup;
  hormonalMarkersForm: FormGroup;
  medicineForm: FormGroup;
  histologyType: FormGroup;
  molecularType: FormGroup;
  radiotherapyForm: FormGroup;
  subscription: Subscription;
  surgicalForm: FormGroup;
  treatmentForm: FormGroup;
  minDate = new Date(new Date().getFullYear() - 110, 0, 1);

  metastesesOptions: IKeyValue[] = [...metastesesOptions];
  nodalInvolvementOptions: IKeyValue[] = [...nodalInvolvementOptions];
  breastCancerTNM: IKeyValue[] = [...breastCancerTNM];
  historicalGrade: IKeyValue[] = [...historicalGrade];
  rhre = [...rhre];
  rhrp = [...rhrp];
  stages = [...stages];
  etapaOEstado = [...etapaOEstado];
  ki67 = [...KI67];
  her2 = [...her2];
  histologyTypeList = [...histologyTypeList];
  molecularTypeList = [...molecularTypeList]
  errorMsgStage = '';
  formError: boolean;
  stageValid = false;

  // Constants
  _hasTreatment = 'hasTreatment';
  _rhrp = 'rhrp';
  _rhre = 'rhre';
  _inCourse = 'inCourse'
  _historicalGrade = 'histologicalGrade';
  _tumorSize = 'tumorSize';
  _lymphnodeDetail = 'lymphnodeDetail';
  _metasteseDetail = 'metasteseDetail';
  _her2 = 'her2';
  _ki67 = 'ki67';
  _otherInCourse = 'otherInCourse';
  _corticosteroidsInCourse = 'corticosteroidsInCourse';
  _startDate = 'startDate';
  _endDate = 'endDate';
  _stage = 'stage'

  constructor(
    private formGroupDirective: FormGroupDirective,
    private modalSrv: ModalService,
    private loaderSrv: SpinnerService,
    private breastCancerCalcSrv: BreastCancerService,
    private activatedRouter: ActivatedRoute,
    private caseInProgressSrv: CaseDataService,
    private formUtils: FormUtilsService,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {

    this.subscription = new Subscription();
    // Initialize breastcancer type form
    this.showBirthDate = !this.hasCaseInCourse() && !this.hasCaseInParams();
    if (this.hasCaseInCourse() && !this.data) {
      this.initForm({ ...JSON.parse(localStorage.getItem('CASE_IN_COURSE')), birthDate: new Date().toISOString() });
    } else {
      this.initForm(this.data ? this.data : null);
    }

    this.subscription = new Subscription();

    // Initialize surgical form of treatment type block
    this.surgicalForm = new FormGroup({
      hasTreatment: new FormControl({ value: false, disabled: true }, []),
      exeresis: new FormControl('', []),
      interventionDate: new FormControl('', [])
    });
    this.hormonalMarkersForm = new FormGroup({
      rhre: new FormControl({ disabled: false, value: '' }, []),
      rhrp: new FormControl({ disabled: false, value: '' }, [])
    });
    // Initialize chemotherapy form of treatment type block
    this.chemotherapyForm = new FormGroup({
      hasTreatment: new FormControl({ value: false, disabled: true }, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize radiotherapy form of treatment type block
    this.radiotherapyForm = new FormGroup({
      hasTreatment: new FormControl({ value: false, disabled: true }, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize immunology form of treatment type block
    this.immunotherapyForm = new FormGroup({
      hasTreatment: new FormControl({ value: false, disabled: true }, []),
      inCourse: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize medicine form of treatment type block
    this.medicineForm = new FormGroup({
      hasTreatment: new FormControl({ value: false, disabled: true }, []),
      corticosteroids: new FormControl(false, []),
      corticosteroidsInCourse: new FormControl('', []),
      corticosteroidsStartDate: new FormControl('', []),
      corticosteroidsEndDate: new FormControl({ disabled: true, value: '' }, []),
      other: new FormControl(false, []),
      otherInCourse: new FormControl('', []),
      otherStartDate: new FormControl('', []),
      otherEndDate: new FormControl({ disabled: true, value: '' }, [])
    });
    // Initialize treatement form
    this.treatmentForm = new FormGroup({});
    // Initialize histology form of histology block
    this.histologyForm = new FormGroup({
      histologicalGrade: new FormControl('', []),
      stage: new FormControl({ disabled: false }, [Validators.required]),
      tumorSize: new FormControl({ disabled: false, value: '' }, []),
      lymphnodeDetail: new FormControl({ disabled: false, value: '' }, []),
      metasteseDetail: new FormControl({ disabled: false, value: '' }, []),
      her2: new FormControl({ disabled: false, value: '' }, []),
      ki67: new FormControl({ disabled: false, value: '' }, []),
    });
    // We init the form control listener
    this.initListenerForm();
    // If we have data, is edit mode
    if (this.data) {
      this.patchDataValues();
    }
    // We init histology form listener after patch data to avoid call back server
    this.initBasicDataListenerForm();
    this.initHistologyAndHormonalFormListener();

    // Formulario para el div
    this.collapsableForm = new FormGroup({
      surgical: new FormControl(this.surgicalForm.controls[this._hasTreatment].value),
      chemotherapy: new FormControl(this.chemotherapyForm.controls[this._hasTreatment].value),
      radiotherapy: new FormControl(this.radiotherapyForm.controls[this._hasTreatment].value),
      immunotherapy: new FormControl(this.immunotherapyForm.controls[this._hasTreatment].value),
    });

    this.formError = this.checkAllFieldsFalse(this.collapsableForm);
    // this.isFullflled();
  }

  initForm = (caseData?: any) => {
    this.basicDataForm = new FormGroup({
      birthDate: new FormControl({ disabled: false, value: caseData ? caseData.birthDate : '' }, [
        CalcValidators.age(18, 100),
        Validators.required
      ]),
      age: new FormControl({ disabled: true, value: caseData ? caseData.age : '' }, [Validators.required]),
      diagnosisDate: new FormControl({ disabled: false, value: caseData ? caseData.diagnosisDate : '' },
        [
          Validators.required,
          this.fechaNoPosterior.bind(this),
        ]),
      histologyType: new FormControl({ disabled: false, value: '' }, [Validators.required]),
      molecularType: new FormControl({ disabled: false, value: '' }, [Validators.required]),
    });

    if (this.showBirthDate) {
      this.basicDataForm.addControl(
        'birthDate',
        new FormControl(caseData ? { disabled: true, value: this.birthDate ? this.birthDate : caseData.birthDate } : '', [
          CalcValidators.age(18, 100),
          Validators.required
        ])
      );
      this.basicDataForm.controls.birthDate.valueChanges.subscribe((value) => {
        this.birthDate = value
      });
    }

    this.basicDataForm.controls.diagnosisDate.addValidators(this.fechaNoAnterior.bind(this));

    if (this.formGroupDirective.form) {
      this.formGroupDirective.form.setControl('basicDataForm', this.basicDataForm);
    }

  }
  // Method to check if we have a case in progress assigned
  hasCaseInCourse = (): boolean => this.caseInProgressSrv.hasInCourse();

  hasCaseInParams = (): boolean => {
    const idCaseValue = this.activatedRouter.snapshot.paramMap.get('idCase')
    // Devolvemos true si no es null o valor vacío
    return idCaseValue !== null && idCaseValue !== '';
  }

  // Method which set age form from date change detected
  calcAge = (data: MatDatepickerInputEvent<Date>): void => {
    const date = moment(data.value);
    this.basicDataForm.get('age').setValue(moment().year() - date.year());
  }

  fechaNoPosterior = (control: FormControl) => {
    const enteredDate = new Date(control.value);
    const actualDate = new Date();
    actualDate.setHours(0, 0, 0, 0); // Para comparar solo las fechas sin tener en cuenta la hora
    return enteredDate > actualDate ? { fechaPosterior: true } : null;
  }

  fechaNoAnterior = (control: FormControl) => {
    const enteredDate = new Date(control.value);
    const actualDate = new Date(this.basicDataForm.controls.birthDate.value);
    actualDate.setHours(0, 0, 0, 0); // Para comparar solo las fechas sin tener en cuenta la hora
    return enteredDate < actualDate ? { fechaAnterior: true } : null;
  }

  // isFullflled() {
  //   if (this.typeForm.controls['type'].value !== '') {
  //     // console.log(err)('tengo valor: ' + this.typeForm.controls['type'].value);
  //     if (this.typeForm.controls['type'].value === 2) {
  //       // console.log(err)('CASO A');
  //       this.histologyForm.controls[this._stage].addValidators(Validators.required);
  //       this.histologyForm.updateValueAndValidity();
  //       /* } else if (this.histologyForm.controls[this._historicalGrade].value !== '' ||
  //                                    this.histologyForm.controls['hasUlceration'].value !== ''){
  //         // console.log('CASO B');
  //         this.histologyForm.controls[this._stage].addValidators(Validators.required);
  //         this.histologyForm.updateValueAndValidity();*/
  //     } else {
  //       // console.log(err)('CASO C');
  //       this.histologyForm.controls[this._stage].clearValidators();
  //       this.histologyForm.updateValueAndValidity();
  //     }

  //   }
  // }

  initBasicDataListenerForm = (): void => {
    this.basicDataForm.controls['molecularType'].valueChanges.pipe(debounceTime(500)).subscribe({
      next: (newValue) => {
        this.basicDataForm.controls['molecularType'].setValue(newValue);
        this.predetermineHormonalData(newValue);
      }
    });
  }

  predetermineHormonalData = (value: string): void => {
    [this._rhrp, this._rhre, this._her2].forEach(e => {
      if (e === this._her2) {
        this.histologyForm.controls[e].clearValidators();
        this.histologyForm.controls[e].reset();
        this.histologyForm.controls[e].updateValueAndValidity();
      } else {
        this.hormonalMarkersForm.controls[e].clearValidators();
        this.hormonalMarkersForm.controls[e].reset();
        this.hormonalMarkersForm.controls[e].updateValueAndValidity();
      }
    })
    switch (value) {
      case this.molecularTypeList[0]:
        [this._rhrp, this._rhre].forEach(e => {
          this.hormonalMarkersForm.controls[e].patchValue('POSITIVO');
          this.hormonalMarkersForm.controls[e].disable({ onlySelf: true });
          this.hormonalMarkersForm.controls[e].updateValueAndValidity();
        });

        this.histologyForm.controls[this._her2].patchValue('NEGATIVO');
        this.histologyForm.controls[this._her2].disable({ onlySelf: true });
        this.histologyForm.controls[this._her2].updateValueAndValidity();
        break;

      case this.molecularTypeList[1]:
        this.hormonalMarkersForm.controls[this._rhre].patchValue('POSITIVO');
        this.hormonalMarkersForm.controls[this._rhre].enable({ onlySelf: true })
        this.hormonalMarkersForm.controls[this._rhre].updateValueAndValidity();

        this.hormonalMarkersForm.controls[this._rhrp].patchValue('NEGATIVO');
        this.hormonalMarkersForm.controls[this._rhrp].enable({ onlySelf: true });
        this.hormonalMarkersForm.controls[this._rhrp].updateValueAndValidity();

        this.histologyForm.controls[this._her2].patchValue('POSITIVO');
        this.histologyForm.controls[this._her2].disable({ onlySelf: true });
        this.histologyForm.controls[this._her2].updateValueAndValidity();
        break;

      case this.molecularTypeList[2]:
        this.hormonalMarkersForm.controls[this._rhrp].patchValue('NEGATIVO');
        this.hormonalMarkersForm.controls[this._rhrp].disable({ onlySelf: true });
        this.hormonalMarkersForm.controls[this._rhrp].updateValueAndValidity();

        this.hormonalMarkersForm.controls[this._rhre].patchValue('NEGATIVO');
        this.hormonalMarkersForm.controls[this._rhre].disable({ onlySelf: true });
        this.hormonalMarkersForm.controls[this._rhre].updateValueAndValidity();

        this.histologyForm.controls[this._her2].patchValue('POSITIVO');
        this.histologyForm.controls[this._her2].disable({ onlySelf: true });
        this.histologyForm.controls[this._her2].updateValueAndValidity();
        break;

      case this.molecularTypeList[3]:
        [this._rhrp, this._rhre].forEach(e => {
          this.hormonalMarkersForm.controls[e].patchValue('NEGATIVO');
          this.hormonalMarkersForm.controls[e].disable({ onlySelf: true });
          this.hormonalMarkersForm.controls[e].updateValueAndValidity();
        });

        this.histologyForm.controls[this._her2].patchValue('NEGATIVO');
        this.histologyForm.controls[this._her2].disable({ onlySelf: true });
        this.histologyForm.controls[this._her2].updateValueAndValidity();
        break;

      case this.molecularTypeList[4]:
        this.histologyForm.controls[this._stage].patchValue(this.stages[this.stages.length - 1]);
        this.hormonalMarkersForm.controls[this._rhre].disable({ onlySelf: true });
        this.hormonalMarkersForm.controls[this._rhrp].disable({ onlySelf: true });
        this.histologyForm.controls[this._her2].disable({ onlySelf: true });
        break;

      default:
        this.hormonalMarkersForm.controls[this._rhre].enable({ onlySelf: true })
        this.hormonalMarkersForm.controls[this._rhrp].enable({ onlySelf: true });
        this.histologyForm.controls[this._her2].enable({ onlySelf: true });
        this.hormonalMarkersForm.controls[this._rhre].updateValueAndValidity();
        this.hormonalMarkersForm.controls[this._rhrp].updateValueAndValidity();
        this.histologyForm.controls[this._her2].updateValueAndValidity();
        break;
    }
  }

  shouldBeDisabled = (): boolean => {
    return this.formError ||
      !this.stageValid ||
      !Object.values(this.basicDataForm.controls).every(control => control.value) ||
      this.isNotValidateToCalc();
  }

  // Initialize inCourse control listener to enable or disable end date control
  initListenerForm = (): void => {
    // Scope change => When melanoma's type is malign, stage field from Histology form must be required
    // this.basicData.controls['molecularType'].valueChanges.pipe(debounceTime(500)).subscribe({
    //   next: (newValue) => {
    //     if (newValue === Object.values(this.molecularTypeList)[0]) {
    //       this.hormonalMarkersForm.controls[_rhrp].setValue(Object.values(this.rhre)[1]);
    //     }
    //   }
    // });
    // this.surgicalForm.controls[this._inCourse].valueChanges.subscribe({
    //   next: (newValue) => this.enableDisableEndDateControl(this.surgicalForm.controls[this._endDate], newValue)
    // });

    // this.basicDataForm.controls['molecularType'].valueChanges.pipe(debounceTime(500)).subscribe({
    //   next: (newValue) => {
    //     this.basicDataForm.controls['molecularType'].setValue(newValue);
    //     this.predetermineHormonalData(newValue);
    //   }
    // });

    this.basicDataForm.controls.diagnosisDate.valueChanges.subscribe(value => {
      if (!value) {
        this.surgicalForm.get('hasTreatment').disable();
        this.chemotherapyForm.get('hasTreatment').disable();
        this.radiotherapyForm.get('hasTreatment').disable();
        this.immunotherapyForm.get('hasTreatment').disable();
        this.medicineForm.get('hasTreatment').disable();
        this.accordion.closeAll();
        this.setValidatorsSurgicalForm(false);
        this.surgicalForm.controls.hasTreatment.setValue(false);
        this.surgicalForm.updateValueAndValidity();
        this.setValidatorsTreatmentForm(this.chemotherapyForm, false);
        this.chemotherapyForm.controls.hasTreatment.setValue(false);
        this.chemotherapyForm.updateValueAndValidity();
        this.setValidatorsTreatmentForm(this.radiotherapyForm, false);
        this.radiotherapyForm.controls.hasTreatment.setValue(false);
        this.radiotherapyForm.updateValueAndValidity();
        this.setValidatorsTreatmentForm(this.immunotherapyForm, false);
        this.immunotherapyForm.controls.hasTreatment.setValue(false);
        this.immunotherapyForm.updateValueAndValidity();
        this.setCorticosteroidsValidator(false);
        this.setOtherMedicineValidator(false);
        this.medicineForm.controls.hasTreatment.setValue(false);
        this.medicineForm.controls.corticosteroids.setValue(false);
        this.medicineForm.controls.other.setValue(false);
        this.medicineForm.updateValueAndValidity();
      } else {
        this.surgicalForm.get('hasTreatment').enable();
        this.chemotherapyForm.get('hasTreatment').enable();
        this.radiotherapyForm.get('hasTreatment').enable();
        this.immunotherapyForm.get('hasTreatment').enable();
        this.medicineForm.get('hasTreatment').enable();
      }
    });

    this.histologyForm.controls[this._stage].valueChanges.subscribe(value => {
      this.stageValid = !!value.trim()
    });

    this.chemotherapyForm.controls[this._inCourse].valueChanges.subscribe({
      next: (newValue) => this.enableDisableControl(this.chemotherapyForm.controls[this._endDate], newValue)
    });
    this.chemotherapyForm.controls[this._endDate].valueChanges.subscribe({
      next: (newValue) => {
        this.chemotherapyForm.get(this._endDate).markAsTouched();
        if (new Date(newValue) > this.maxDate && this.chemotherapyForm.controls[this._inCourse].value !== 2)
          this.chemotherapyForm.get(this._endDate).addValidators([this.fechaNoPosterior.bind(this)])
      }
    });

    this.radiotherapyForm.controls[this._inCourse].valueChanges.subscribe({
      next: (newValue) => this.enableDisableControl(this.radiotherapyForm.controls[this._endDate], newValue)
    });

    this.radiotherapyForm.controls[this._endDate].valueChanges.subscribe({
      next: (newValue) => {
        this.radiotherapyForm.get(this._endDate).markAsTouched();
        if (new Date(newValue) > this.maxDate)
          this.radiotherapyForm.get(this._endDate).addValidators([this.fechaNoPosterior.bind(this)])
      }
    });
    this.immunotherapyForm.controls[this._inCourse].valueChanges.subscribe({
      next: (newValue) => this.enableDisableControl(this.immunotherapyForm.controls[this._endDate], newValue)
    });

    this.immunotherapyForm.controls[this._endDate].valueChanges.subscribe({
      next: (newValue) => {
        this.immunotherapyForm.get(this._endDate).markAsTouched();
        if (new Date(newValue) > this.maxDate)
          this.immunotherapyForm.get(this._endDate).addValidators([this.fechaNoPosterior.bind(this)])
      }
    });

    this.medicineForm.controls[this._corticosteroidsInCourse].valueChanges.subscribe({
      next: (newValue) => this.enableDisableControl(this.medicineForm.controls['corticosteroidsEndDate'], newValue)
    });
    this.medicineForm.controls[this._otherInCourse].valueChanges.subscribe({
      next: (newValue) => this.enableDisableControl(this.medicineForm.controls['otherEndDate'], newValue)
    });
  }

  /**
   * Method to initialize a listener in histology and hormonal form fields
   */
  initHistologyAndHormonalFormListener = (): void => {
    this.histologyForm.controls['histologicalGrade'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['histologicalGrade'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['tumorSize'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['tumorSize'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['lymphnodeDetail'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['lymphnodeDetail'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls['metasteseDetail'].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls['metasteseDetail'].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls[this._her2].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls[this._her2].value !== '' ? this.calcStage() : '');
    this.histologyForm.controls[this._ki67].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.histologyForm.controls[this._ki67].value !== '' ? this.calcStage() : '');
    this.hormonalMarkersForm.controls[this._rhre].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.hormonalMarkersForm.controls[this._rhre].value !== '' ? this.calcStage() : '');
    this.hormonalMarkersForm.controls[this._rhrp].valueChanges.pipe(
      debounceTime(500)).subscribe(() => this.hormonalMarkersForm.controls[this._rhrp].value !== '' ? this.calcStage() : '');
  }

  /**
   * Method to get the from form data
   */
  calcStage = (): void => {
    const { histologicalGrade, lymphnodeDetail, metasteseDetail, tumorSize, her2 } = this.histologyForm.getRawValue();
    const { rhre, rhrp } = this.hormonalMarkersForm.getRawValue()

    if (
      (histologicalGrade && histologicalGrade.length) ||
      (lymphnodeDetail &&
        lymphnodeDetail.length &&
        metasteseDetail &&
        metasteseDetail.length &&
        tumorSize &&
        tumorSize.length) ||
      (lymphnodeDetail &&
        lymphnodeDetail.length &&
        metasteseDetail &&
        metasteseDetail.length &&
        tumorSize &&
        tumorSize.length &&
        ((rhre && rhre.length) || (rhrp && rhrp.lengh)) &&
        (her2 && her2.length))
    ) {
      const loader = this.loaderSrv.showSpinner();
      const data = {
        histologicalGrade,
        lymphnodeDetail,
        metasteseDetail,
        tumorSize,
        her2,
        rhre,
        rhrp
      };
      this.breastCancerCalcSrv.breastCancerStageCalcPost(data).subscribe(
        (response) => {
          if (!instanceOfError(response)) {
            this.errorMsgStage = response.msg;
            this.histologyForm.controls[this._stage].setValue(response.stage);
            this.histologyForm.controls[this._stage].updateValueAndValidity();
          } else {
            this.modalSrv.openModalDanger('ERROR.ERROR', (response as any).err?.key);
          }
          loader.close();
        },
        (error) => loader.close()
      );
    }
  }

  // Method to enable or disable select details control
  enableDisableControl = (control: AbstractControl, value: number | string | boolean): void => {
    if (typeof value === "string") {
      value = +value;
    }
    if (!value === false) {
      control.addValidators(Validators.required);
      control.enable({ onlySelf: true });
    } else {
      control.clearValidators();
      control.reset();
      control.disable({ onlySelf: true });
    }
    control.updateValueAndValidity();
  }

  // Method to expand or collapse panel of medicine form in treatment block
  toggleExpansionMedicineForm = (event: MatCheckboxChange, panel: MatExpansionPanel, option: number): void => {
    panel.toggle();
    option === 0 ? this.setCorticosteroidsValidator(event.checked) : this.setOtherMedicineValidator(event.checked);
  }

  isRequired(control: AbstractControl): boolean {
    try {
      return control.hasValidator(Validators.required);
    } catch (error) {
      return false;
    }
  }

  // Method to set Other form validator in Medicine form
  setOtherMedicineValidator = (checked: boolean): void => {
    if (checked) {
      this.medicineForm.controls[this._otherInCourse].addValidators(Validators.required);
      this.medicineForm.controls['otherStartDate'].addValidators(Validators.required);
    } else {
      this.medicineForm.controls[this._otherInCourse].clearValidators();
      this.medicineForm.controls['otherStartDate'].clearValidators();
      this.medicineForm.controls['otherEndDate'].clearValidators();
      this.medicineForm.controls[this._otherInCourse].reset();
      this.medicineForm.controls['otherStartDate'].reset();
      this.medicineForm.controls['otherEndDate'].reset();
    }
    this.medicineForm.controls[this._otherInCourse].updateValueAndValidity();
    this.medicineForm.controls['otherStartDate'].updateValueAndValidity();
    this.medicineForm.controls['otherEndDate'].updateValueAndValidity();
  }

  // Method to set Corticosteroids form validator in Medicine form
  setCorticosteroidsValidator = (checked: boolean): void => {
    if (checked) {
      this.medicineForm.controls[this._corticosteroidsInCourse].addValidators(Validators.required);
      this.medicineForm.controls['corticosteroidsStartDate'].addValidators(Validators.required);
    } else {
      this.medicineForm.controls[this._corticosteroidsInCourse].clearValidators();
      this.medicineForm.controls['corticosteroidsStartDate'].clearValidators();
      this.medicineForm.controls['corticosteroidsEndDate'].clearValidators();
      this.medicineForm.controls[this._corticosteroidsInCourse].reset();
      this.medicineForm.controls['corticosteroidsStartDate'].reset();
      this.medicineForm.controls['corticosteroidsEndDate'].reset();
    }
    this.medicineForm.controls[this._corticosteroidsInCourse].updateValueAndValidity();
    this.medicineForm.controls['corticosteroidsStartDate'].updateValueAndValidity();
    this.medicineForm.controls['corticosteroidsEndDate'].updateValueAndValidity();
  }

  // Method to expand or collapse panel of treatment block except medicine form
  toggleExpansion = (event: MatSlideToggleChange, panel: MatExpansionPanel, formType: string): void => {
    panel.toggle();
    this.updateCollapsableForm(formType, event.checked);
    switch (formType) {
      case 'surgical':
        this.setValidatorsSurgicalForm(event.checked);
        break;
      case 'chemotherapy':
        this.setValidatorsTreatmentForm(this.chemotherapyForm, event.checked);
        break;
      case 'radiotherapy':
        this.setValidatorsTreatmentForm(this.radiotherapyForm, event.checked);
        break;
      case 'immunotherapy':
        this.setValidatorsTreatmentForm(this.immunotherapyForm, event.checked);
        break;
      case 'medicine':
        this.setValidatorsTreatmentForm(this.medicineForm, event.checked);
        break;
    }
  }

  checkAllFieldsFalse(form: FormGroup): boolean {
    let formCheck = true;
    // Itera sobre todos los controles del formulario
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control.value && this.histologyForm.controls[this._stage].value) {
        formCheck = false;
      }
    });

    return formCheck;
  }

  updateCollapsableForm(formName: string, checked: boolean): void {
    // Almaceno las claves de mi formulario
    const keys = Object.keys(this.collapsableForm.controls);
    keys.forEach(key => {
      // Verificar si el valor del control es igual al parámetro formCheck
      if (key === formName) {
        // Establecer el valor del control en el mismo valor que la clave modificada
        this.collapsableForm.get(key).setValue(checked);
        this.formError = this.checkAllFieldsFalse(this.collapsableForm);
      }
    });
  }

  // Method to set validators of treatment block form except surgical form
  setValidatorsTreatmentForm(form: FormGroup, checked: boolean): void {
    if (checked) {
      form.controls[this._inCourse].addValidators([Validators.required, this.fechaNoPosterior.bind(this)]);
      // form.controls[this._startDate].addValidators(Validators.required);
    } else {
      form.reset();
      form.controls[this._inCourse].clearValidators();
      // form.controls[this._startDate].clearValidators();
      // form.controls[this._endDate].clearValidators();

      // Fixed: 06/03/2024 -> Anteriormente el formulario se reseteaba usando solo la funcion .reset() sin parametros.
      // Esto daba lugar a errores, por ejemplo al volver a cargar el formulario, los campos tocados pero no rellenos (abrir y cerrar)
      // se marcaban abiertos bloqueando el formulario y trayendo información no valida
      // Al setear los campos manualmente en el reset, evitamos este comportamiento no deseado.
      form.reset({
        hasTreatment: false,
        inCourse: '',
        startDate: '',
        endDate: { disabled: true, value: '' }
      });
    }
    form.controls[this._inCourse].updateValueAndValidity();
    // form.controls[this._startDate].updateValueAndValidity();
    // form.controls[this._endDate].updateValueAndValidity();
  }

  // Method to set validators of Surgical form
  setValidatorsSurgicalForm = (checked: boolean): void => {

    // creamos una variable donde almacenaremos el valor original de typeForm
    if (checked) {
      // Añadimos validacion al campo exersis y al de intervención
      this.surgicalForm.controls['exeresis'].addValidators(Validators.required);
      this.surgicalForm.controls['interventionDate'].addValidators([
        Validators.required, this.formUtils.dateRangeValidator(this.basicDataForm.controls.diagnosisDate.value)
      ]);

      // Suscribimos el cambio de la fecha de diagnostico de forma que siempre valide si es anterior o igual que el campo de fecha de intervención
      // Asignamos a updatedDiagnosedDate el valor actualizado para el validador

      this.subscription = this.basicDataForm.controls.diagnosisDate.valueChanges.subscribe((value) => {
        this.surgicalForm.controls['interventionDate'].clearValidators();
        this.surgicalForm.controls['interventionDate'].addValidators([Validators.required, this.formUtils.dateRangeValidator(value)]);
        this.surgicalForm.controls['interventionDate'].updateValueAndValidity();
      });

    } else {
      // reiniciamos los validadores y el formulario si no estamos accediendo a el
      this.surgicalForm.controls['exeresis'].clearValidators();
      this.surgicalForm.controls['interventionDate'].clearValidators();
      // this.surgicalForm.reset();
      this.surgicalForm.reset({ hasTreatment: false, exeresis: '', interventionDate: '' });
      this.surgicalForm.updateValueAndValidity();
      this.subscription.unsubscribe();

    }

    // Actualizamos el formulario a consecuencia
    this.surgicalForm.controls['exeresis'].updateValueAndValidity();
    this.surgicalForm.controls['interventionDate'].updateValueAndValidity();
  }
  // Method to clean every form of this page
  cleanForms = (): void => {
    // Call ngOnit who initialize every form. So it has been resetted
    // this.medicineAccordion.closeAll();
    this.accordion.closeAll();
    this.basicDataForm.clearAsyncValidators()
    this.basicDataForm.reset({
      age: { value: '', disable: false },
      diagnosisDate: { value: '', disable: false },
      histologyType: { value: '', disable: false },
      molecularType: { value: '', disable: false },
    });
    this.basicDataForm.updateValueAndValidity();
    this.setValidatorsSurgicalForm(false);
    this.surgicalForm.controls.hasTreatment.setValue(false);
    this.surgicalForm.updateValueAndValidity();
    this.setValidatorsTreatmentForm(this.chemotherapyForm, false);
    this.chemotherapyForm.controls.hasTreatment.setValue(false);
    this.chemotherapyForm.updateValueAndValidity();
    this.setValidatorsTreatmentForm(this.radiotherapyForm, false);
    this.radiotherapyForm.controls.hasTreatment.setValue(false);
    this.radiotherapyForm.updateValueAndValidity();
    this.setValidatorsTreatmentForm(this.immunotherapyForm, false);
    this.immunotherapyForm.controls.hasTreatment.setValue(false);
    this.immunotherapyForm.updateValueAndValidity();
    this.setCorticosteroidsValidator(false);
    this.setOtherMedicineValidator(false);
    this.medicineForm.controls.hasTreatment.setValue(false);
    this.medicineForm.controls.corticosteroids.setValue(false);
    this.medicineForm.controls.other.setValue(false);
    this.medicineForm.updateValueAndValidity();
    if (this.treatmentForm.controls.treatmentList) {
      while ((this.treatmentForm.controls.treatmentList as FormArray).length) {
        (this.treatmentForm.controls.treatmentList as FormArray).removeAt(0);
      }
    }
    this.errorMsgStage = '';
    this.histologyForm.clearAsyncValidators()
    this.histologyForm.reset({
      histologicalGrade: { value: '', disable: false },
      stage: { value: '', disable: false },
      tumorSize: { value: '', disable: false },
      lymphnodeDetail: { value: '', disable: false },
      metasteseDetail: { value: '', disable: false },
    });
    this.hormonalMarkersForm.clearAsyncValidators()
    this.hormonalMarkersForm.reset({
      rhre: { value: '', enabled: false },
      rhrp: { value: '', disable: false },
    })
    this.histologyForm.updateValueAndValidity();
    this.hormonalMarkersForm.updateValueAndValidity();
    this.collapsableForm.reset({
      surgical: false,
      chemotherapy: false,
      radiotherapy: false,
      immunoTherapy: false,
    }
    );
    this.formError = this.checkAllFieldsFalse(this.collapsableForm);
  }

  // Method to check if forms are valid. Return true if not valid, otherwise return false
  isNotValidateToCalc = (): boolean => {
    if (
      !this.basicDataForm.valid ||
      !this.surgicalForm.valid ||
      !this.chemotherapyForm.valid ||
      !this.radiotherapyForm.valid ||
      !this.immunotherapyForm.valid ||
      !this.medicineForm.valid ||
      !this.treatmentForm.valid ||
      !this.histologyForm.valid
    ) {
      return true;
    }
    return false;
  }

  markAllAsTouched = (): void => {
    this.surgicalForm.markAllAsTouched();
    this.chemotherapyForm.markAllAsTouched();
    this.radiotherapyForm.markAllAsTouched();
    this.immunotherapyForm.markAllAsTouched();
    this.medicineForm.markAllAsTouched();
    this.treatmentForm.markAllAsTouched();
    this.histologyForm.markAllAsTouched();
    this.basicDataForm.markAllAsTouched();
  }

  // Method which call calc service with the form data
  calc = (): void => {
    this.markAllAsTouched();
    if (!this.isNotValidateToCalc()) {
      const loader = this.loaderSrv.showSpinner();
      const data = this.prepareCalcData();
      this.breastCancerCalcSrv.breastCancerPost(data).subscribe({
        next: (response) => {
          const res = (response as any).err?.msg === 'The stage is incompatible';
          loader.close();
          if (instanceOfError(response)) {
            this.modalSrv.openModalDanger('ERROR.ERROR', res ? 'ERROR.STAGE.INCOMPATIBLE' : 'ERROR.BREAST-CANCER.BREAST-CANCER-CALC.INCOMPATIBLE');
          } else {
            this.dataResult.emit({ data, result: response });

          }
        }
      });
    }
  }

  // Method to prepare data for calc service
  prepareCalcData = (): any => {
    const value: IBreastCancerForm = {
      basicData: {
        birthDate: this.basicDataForm.get('birthDate').value,
        age: this.basicDataForm.get('age').value,
        diagnosisDate: this.basicDataForm.get('diagnosisDate').value,
        histologyType: this.basicDataForm.get('histologyType').value,
        molecularType: this.basicDataForm.get('molecularType').value,
      },
      treatmentType: {
        surgical:
          this.surgicalForm.get(this._hasTreatment).value === false
            ? null
            : {
              exeresis: this.surgicalForm.get('exeresis').value,
              interventionDate: this.surgicalForm.get('interventionDate').value
            },
        chemotherapy:
          this.chemotherapyForm.get(this._hasTreatment).value === false
            ? null
            : {
              inCourse: this.chemotherapyForm.get(this._inCourse).value,
              startDate: this.chemotherapyForm.get(this._startDate).value,
              endDate: this.chemotherapyForm.get(this._endDate).value ?? null
            },
        radiotherapy:
          this.radiotherapyForm.get(this._hasTreatment).value === false
            ? null
            : {
              inCourse: this.radiotherapyForm.get(this._inCourse).value,
              startDate: this.radiotherapyForm.get(this._startDate).value,
              endDate: this.radiotherapyForm.get(this._endDate).value ?? null
            },
        immunoTherapy:
          this.immunotherapyForm.get(this._hasTreatment).value === false
            ? null
            : {
              inCourse: this.immunotherapyForm.get(this._inCourse).value,
              startDate: this.immunotherapyForm.get(this._startDate).value,
              endDate: this.immunotherapyForm.get(this._endDate).value ?? null
            },
        medicines:
          this.medicineForm.get(this._hasTreatment).value === false
            ? null
            : {
              corticosteroids: this.medicineForm.get('corticosteroids').value,
              corticosteroidsInCourse: this.medicineForm.get(this._corticosteroidsInCourse).value,
              corticosteroidsStartDate: this.medicineForm.get('corticosteroidsStartDate').value,
              corticosteroidsEndDate: this.medicineForm.get('corticosteroidsEndDate').value
                ? this.medicineForm.get('corticosteroidsEndDate').value
                : null,
              other: this.medicineForm.get('other').value,
              otherInCourse: this.medicineForm.get(this._otherInCourse).value,
              otherStartDate: this.medicineForm.get('otherStartDate').value,
              otherEndDate: this.medicineForm.get('otherEndDate').value ? this.medicineForm.get('otherEndDate').value : null
            }
      },
      treatment: (this.treatmentForm.controls.treatmentList as FormArray).getRawValue(),
      histology: {
        histologicalGrade: this.histologyForm.get(this._historicalGrade).value ?? null,
        stage: this.histologyForm.get(this._stage).value ?? null,
        tumorSize: this.histologyForm.get(this._tumorSize).value ?? null,
        lymphnodeDetail: this.histologyForm.get(this._lymphnodeDetail).value ?? null,
        metasteseDetail: this.histologyForm.get(this._metasteseDetail).value ?? null,
        her2: this.histologyForm.get(this._her2).value ?? null,
        ki67: this.histologyForm.get(this._ki67).value ?? null
      },
      hormonalMarkers: {
        rhre: this.hormonalMarkersForm.get(this._rhre).value ?? null,
        rhrp: this.hormonalMarkersForm.get(this._rhrp).value ?? null
      }
    };
    return value;
  }


  // Method to patch value to forms in edit mode
  patchDataValues = (): void => {
    // Histology form
    this.histologyForm.patchValue(this.data.histology);
    this.histologyForm.updateValueAndValidity();
    // Medical treatment form @Input in component app-medical-detail
    // Treatment type
    if (this.data.basicData) {
      this.basicDataForm.patchValue(this.data.basicData);
      this.predetermineHormonalData(this.data.basicData.molecularType)
      // this.setValidatorsSurgicalForm(true);
    }
    if (this.data.treatmentType.surgical) {
      this.surgicalForm.get(this._hasTreatment).setValue(true);
      this.surgicalForm.patchValue(this.data.treatmentType.surgical);
      this.setValidatorsSurgicalForm(true);
    }
    if (this.data.treatmentType.chemotherapy) {
      this.chemotherapyForm.get(this._hasTreatment).setValue(true);
      this.chemotherapyForm.patchValue(this.data.treatmentType.chemotherapy);
      this.setValidatorsTreatmentForm(this.chemotherapyForm, true);
    }
    if (this.data.treatmentType.radiotherapy) {
      this.radiotherapyForm.get(this._hasTreatment).setValue(true);
      this.radiotherapyForm.patchValue(this.data.treatmentType.radiotherapy);
      this.setValidatorsTreatmentForm(this.radiotherapyForm, true);
    }
    if (this.data.treatmentType.immunoTherapy) {
      this.immunotherapyForm.get(this._hasTreatment).setValue(true);
      this.immunotherapyForm.patchValue(this.data.treatmentType.immunoTherapy);
      this.setValidatorsTreatmentForm(this.immunotherapyForm, true);
    }
    if (this.data.treatmentType.medicines) {
      this.medicineForm.get(this._hasTreatment).setValue(true);
      this.medicineForm.patchValue(this.data.treatmentType.medicines);
      if (this.data.treatmentType.medicines.corticosteroids) {
        this.setCorticosteroidsValidator(true);
      }
      if (this.data.treatmentType.medicines.other) {
        this.setOtherMedicineValidator(true);
      }
    }
  }
}
