import { CartItem } from './cart-item.model';
import { Melanoma } from './melanoma/melanoma';
import { IMelanomaForm } from './melanoma/melanomaCalcForm.model';

export class ShoppingCart {
  public items: CartItem[] = new Array<CartItem>();
  // public deliveryOptionId: string;
  // public grossTotal: number = 0;
  // public deliveryTotal: number = 0;
  public itemsTotal = 0;

  public updateFrom(src: ShoppingCart) {
    this.items = src.items;
    // this.deliveryOptionId = src.deliveryOptionId;
    // this.grossTotal = src.grossTotal;
    // this.deliveryTotal = src.deliveryTotal;
    this.itemsTotal = src.itemsTotal;
  }
}
