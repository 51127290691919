import { IKeyValue } from './breast-cancer-calc-interfaces'
export const historicalGrade: IKeyValue[] = [
  { key: 'I', value: 'I' },
  { key: 'II', value: 'II' },
  { key: 'III', value: 'III' },
];

export const rhre: IKeyValue[] = [
  { key: '', value: '' },
  { key: 'POSITIVE', value: 'POSITIVO' },
  { key: 'NEGATIVE', value: 'NEGATIVO' }
]

export const rhrp: IKeyValue[] = [
  { key: '', value: '' },
  { key: 'POSITIVE', value: 'POSITIVO' },
  { key: 'NEGATIVE', value: 'NEGATIVO' }
]

export const her2: IKeyValue[] = [
  { key: '', value: '' },
  { key: 'POSITIVE', value: 'POSITIVO' },
  { key: 'NEGATIVE', value: 'NEGATIVO' }
]

export const KI67: IKeyValue[] = [
  { key: '', value: '' },
  { key: '≥20%', value: '≥20%' },
  { key: '<20%', value: '<20%' },

]

export const stages = [
  '', '0', 'IA', 'IB', 'IIA', 'IIB', 'IIC', 'III', 'IV'
]

export const listGanglionOptions: IKeyValue[] = [
  { key: 0, value: 'MELANOMA-CALC.HISTOLOGY.INDETERMINATED' },
  { key: 1, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.0' },
  { key: 2, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.1' },
  { key: 3, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.2' },
  { key: 4, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.3' },
  { key: 5, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.4' },
  { key: 6, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.5' },
  { key: 7, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.6' },
  { key: 8, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.7' },
  { key: 9, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.8' },
  { key: 10, value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.9' }
];

export const molecularTypeList = [
  'Luminal A',
  'Luminal B',
  'HER2 positivo sobrexpresado',
  'Basal o triple negativo',
  'Bajo en claudina',
  'No disponible'
]
export const histologyTypeList = [
  'Ductal- in situ',
  'Ductal- infiltrante',
  'Ductal- tubular',
  'Ductal - mucinoso',
  'Ductal- medular',
  'Lobulillar- in situ',
  'Lobulillar- infiltrante',
  'No disponible'
];

export const metastesesOptions: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'M0',
    value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M0'
  },
  {
    key: 'M1',
    value: 'MELANOMA-CALC.HISTOLOGY.HAVE-METASTESES.M1'
  }
];

export const nodalInvolvementOptions: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'N0',
    value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N0'
  },
  {
    key: 'N1',
    value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N1'
  },
  {
    key: 'N2',
    value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N2'
  },
  {
    key: 'N3',
    value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.N3'
  },
  {
    key: 'Nx',
    value: 'MELANOMA-CALC.HISTOLOGY.HASGANGLION.VALUES.Nx'
  }
];
export const breastCancerTNM: IKeyValue[] = [
  {
    key: '',
    value: ''
  },
  {
    key: 'T1',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.T1'
  },
  {
    key: 'T2',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.T2'
  },
  {
    key: 'T3',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.T3'
  },
  {
    key: 'T4',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.T4'
  },
  {
    key: 'Tis',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.Tis'
  },
  {
    key: 'Tx',
    value: 'BREAST-CANCER-CALC.HISTOLOGY.TNM-CLASSIFICATION.Tx'
  }
];

export const etapaOEstado: IKeyValue[] = [
  { key: "a", value: '0' },
  { key: "b", value: 'IA' },
  { key: "c", value: 'IB' },
  { key: "d", value: 'IIA' },
  { key: "e", value: 'IIB' },
  { key: "f", value: 'IIIA' },
  { key: "g", value: 'IIIB' },
  { key: "h", value: 'IIIC' },
  { key: "i", value: 'IV' },

]
