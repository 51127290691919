import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { instanceOfError } from '@app/shared/utils/commonMethods';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenusService } from '../api';
import { InlineResponse200 } from '../api/model/inlineResponse200';
import { MenuElement } from '../api/model/menuElement';
import { GenericRequestService } from '../generic-request/generic-request.service';
import { SpinnerService } from '../spinner/spinner.service';
import { catchError, map, tap } from 'rxjs/operators';

export interface MenuTitles {
  title: string;
  subtitle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public menuTitles: MenuTitles;
  public currentLink: string;
  menuResponse: MenuElement[] = [];

  menuSubject$: BehaviorSubject<MenuElement[]> = new BehaviorSubject<MenuElement[]>(null);

  constructor(
    private spinnerService: SpinnerService,
    private genericRequest: GenericRequestService,
    private router: Router,
    private translateService: TranslateService,
    private menusApiService: MenusService,
    private activatedRouter: ActivatedRoute
  ) {
    router.events.subscribe((event) => {
      // see also
      if (event instanceof NavigationEnd && this.menuResponse.length > 0) {
        //this.getTitleSubtitle(event.url);
      }
    });
  }

  setCurrentMenu(currentMenu: string){
    this.currentLink = currentMenu;
  }

  getCurrentMenu(){
    return this.currentLink;
  }

  getMenu() {
    const loading = this.spinnerService.showSpinner();
    this.menusApiService.menusGet(this.translateService.currentLang).subscribe(
      (response: MenuElement[]) => {
        loading.close();
        if (!instanceOfError(response)) {
          this.menuResponse = response;
          this.menuSubject$.next(response);
        }
      },
      (err) => {
        console.error(err);
        loading.close();
      }
    );
  }

  getMenuLabels(): Observable<void> {
    return this.menusApiService.menusGet(this.translateService.currentLang).pipe(
      tap((response: MenuElement[]) => {
        if (!instanceOfError(response)) {
          this.menuResponse = response;
          this.menuSubject$.next(response);
        }
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      }),
      map(() => {}) // Map to void since we don't need to return anything
    );
  }

  getMenuWordpress() {
    const loading = this.spinnerService.showSpinner();
    this.genericRequest.get('https://wordpress.gft.com/maresel/wp-json/wp/v2/pages').subscribe(
      (response: any) => {
        this.menuResponse[0].menu = [];
        for (let i = 0; i < response.length; i++) {
          const linkWP = response[i].link;
          if (linkWP.indexOf('https://wordpress.gft.com/maresel/es/') === 0 && linkWP.length !== 'https://wordpress.gft.com/maresel/es/'.length) {
            const opciones = linkWP.substr('https://wordpress.gft.com/maresel/es/'.length).split('/');
            this.treat(this.menuResponse[0].menu, opciones, 0, response[i]);
          }
        }
        loading.close();
      },
      (err) => {
        console.error(err);
        loading.close();
      }
    );
  }

  treat(menu: any, opciones: any, p: any, response: any) {
    if (opciones[opciones.length - 1] === '') {
      delete opciones[opciones.length - 1];
      opciones.length = opciones.length - 1;
    }
    if (menu === undefined) {
      menu = [];
    }
    let newMenu: any = { text: response.title.rendered, content: '/riesgos/' + response.slug };
    let exists = false;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].text === response.title.rendered) {
        exists = true;
        newMenu = menu[i];
      }
    }

    if (opciones.length === p + 1) {
      if (!exists) {
        menu[menu.length] = newMenu;
      } else {
        newMenu.text = response.title.rendered;
        '/riesgos/' + response.slug;
      }
    } else {
      if (!exists) {
        menu[menu.length] = newMenu;
      }
      if (newMenu.menu === undefined) {
        newMenu.menu = [];
      }
      this.treat(newMenu.menu, opciones, p + 1, response);
    }
  }

  getTitleMenu(content: string, menu: MenuElement[], result?: MenuTitles): MenuTitles {
    if (!result) {
      result = {
        title: '',
        subtitle: ''
      };
    }
    for (let index = 0; index < menu.length; index++) {
      const m = menu[index];

      if (content?.toLocaleLowerCase() === m?.content?.toLocaleLowerCase()) {
        result.title = m.text;
        break;
      } else if (m?.menu?.length > 0) {
        this.getTitleMenu(content, m.menu, result);
      }
    }

    return result;
  }

  getSubtitleMenu(text: string, allMenus: MenuElement[], menuTitles?: MenuTitles) {
    for (let index = 0; index < allMenus.length; index++) {
      const m = allMenus[index];
      const findChild = m?.menu?.find((mChild) => mChild?.content?.toLocaleLowerCase() === text?.toLocaleLowerCase());
      if (findChild) {
        if (!menuTitles.subtitle) {
          menuTitles.subtitle = m.text;
        } else {
          menuTitles.subtitle = m.text + ' > ' + menuTitles.subtitle;
        }
        this.getSubtitleMenu(m.text, this.menuResponse, menuTitles);
      } else if (m?.menu?.length > 0) {
        this.getSubtitleMenu(text, m?.menu, menuTitles);
      }
    }
    return undefined;
  }

  getTitleSubtitle(url: string): MenuTitles {
    var menuTitles: MenuTitles = {
      title: '',
      subtitle: ''
    };
    menuTitles = this.getTitleMenu(url, this.menuResponse, menuTitles);
    this.getSubtitleMenu(url, this.menuResponse, menuTitles);
    return menuTitles;
  }

  clearMenu(): void {
    this.menuResponse = [];
    this.menuSubject$.next([]);
  }
}
