import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { RolesGuard } from './core/guards/roles.guard';
import { ADMINCASES, ADMINISTRATOR, ADMINUSERS, CHIEFSUSCRIPTOR, CONSULTA, DOCTOR, RESPCONTENT, SUSCRIPTOR } from './shared/utils/commonMethods';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'registry',
    loadChildren: () => import('./pages/registry/registry.module').then((m) => m.RegistryPageModule) // Sin Guardian
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then((m) => m.UnauthorizedPageModule) // Sin Guardian
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./pages/recover-pass/recover-pass.module').then((m) => m.RecoverPassPageModule) // Sin Guardian
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule) // Sin Guardian
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-page/privacy-page.module').then((m) => m.PrivacyPageModule) // Sin Guardian
  },
  // {
  //   path: 'cookie-policy',
  //   loadChildren: () => import('./pages/cookies-page/cookies-page.module').then((m) => m.CookiesPageModule) // Sin Guardian
  // },
  {
    path: 'risks/:content',
    loadChildren: () => import('./pages/maresel-template-page/maresel-template-page.module').then((m) => m.MareselTemplatePagePageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR, CONSULTA, RESPCONTENT]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'risks/:content/:order',
    loadChildren: () => import('./pages/maresel-template-page/maresel-template-page.module').then((m) => m.MareselTemplatePagePageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR, CONSULTA, RESPCONTENT]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'contact-suggestions',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'contact-suggestions/:content',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'work-area',
    loadChildren: () => import('./pages/work-area/work-area.module').then((m) => m.WorkAreaPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'user-management',
    loadChildren: () => import('./pages/user-management/user-management.module').then((m) => m.UserManagementPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINUSERS]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'content-browser',
    loadChildren: () => import('./pages/content-browser/content-browser.module').then((m) => m.ContentBrowserPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'metabolic-calculator',
    loadChildren: () => import('./pages/metabolic-calculator/metabolic-calculator.module').then((m) => m.MetabolicCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'metabolic-calculator/:idCase',
    loadChildren: () => import('./pages/metabolic-calculator/metabolic-calculator.module').then((m) => m.MetabolicCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'melanoma-calculator',
    loadChildren: () => import('./pages/melanoma-calculator/melanoma-calculator.module').then((m) => m.MelanomaCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'melanoma-calculator/:idCase',
    loadChildren: () => import('./pages/melanoma-calculator/melanoma-calculator.module').then((m) => m.MelanomaCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'breast-cancer-calculator',
    loadChildren: () => import('./pages/breast-cancer-calculator/breast-cancer-calculator.module').then((m) => m.BreastCancerCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'breast-cancer-calculator/:idCase',
    loadChildren: () => import('./pages/breast-cancer-calculator/breast-cancer-calculator.module').then((m) => m.BreastCancerCalculatorPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'drug-finder',
    loadChildren: () => import('./pages/drug-finder/drug-finder.module').then((m) => m.DrugFinderPageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'verification-email',
    loadChildren: () => import('./pages/verification-email/verification-email.module').then((m) => m.VerificationEmailPageModule)
  },
  {
    path: 'case-detail/:idCase',
    loadChildren: () => import('./pages/case-detail-page/case-detail-page.module').then((m) => m.CaseDetailPagePageModule),
    data: {
      roles: [ADMINISTRATOR, ADMINCASES, SUSCRIPTOR, CHIEFSUSCRIPTOR, DOCTOR]
    },
    canActivate: [AuthorizationGuard, RolesGuard]
  },
  {
    path: 'search/:searchValue',
    loadChildren: () => import('./search/search.module').then((m) => m.SearchPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'articles',
    loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'articles/:slug',
    loadChildren: () => import('./pages/article/article.module').then((m) => m.ArticlePageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'form/non-medical-forms',
    loadChildren: () => import('./pages/non-medical-forms/non-medical-forms.module').then((m) => m.NonMedicalFormsPageModule),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'form/confidential-reports',
    loadChildren: () => import('./pages/confidential-reports/confidential-reports.module').then((m) => m.ConfidentialReportsPageModule),
    canActivate: [AuthorizationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
