import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTable } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { OperandosService, Rates } from '@app/core/services/api';
import { Product } from '@app/core/services/api/model/product.model';
import { CaseDataService } from '@app/core/services/case-data/case-data.service';
import { MenuService } from '@app/core/services/menu/menu.service';
import { ShoppingCartService } from '@app/core/services/shopping-cart/shopping-cart.service';
import { ModalService } from '@app/core/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'rxjs/operators';
import { DOCTOR } from '../utils/commonMethods';

export interface ILabelLink {
  label: string; // Montañismo
  link: string; // /risks/montanimos
}
@Component({
  selector: 'app-tarifas-table',
  templateUrl: './tarifas-table.component.html',
  styleUrls: ['./tarifas-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TarifasTableComponent implements OnInit {
  @Input() detailCaseView = false;
  @Input() editMode = false;
  @Input() showButton = true;
  @Input() indexBlink: number;
  @Input() title: string;
  @Input() resultTable = false;
  @Input() calcDisclaimer = false;
  @Output() removeId: EventEmitter<string> = new EventEmitter();
  @Output() changeData: EventEmitter<Rates> = new EventEmitter();
  @Output() calc: EventEmitter<boolean> = new EventEmitter();
  displayedColumnsGroup: string[] = [];
  displayedColumnsMock: string[] = [];
  numRowFooter = 0;
  dataSourceMock: any[] = [];
  minWidthRow = 0;
  role = '';
  resultFooter: string[][] = [];
  //console = console;
  _dataSource: Rates;
  operands: string[];
  public newData: any[] = [];
  public dataCard: Product = new Product({});
  literals = {
    ES: 'calculador metabólico',
    EN: 'metabolic calculator',
    FR: 'calculateur métabolique',
    PT: 'calculador metabólico'
  };
  currentLanguage: string;

  @ViewChild('tableEvent') tableEvent;
  _showBC = true;

  constructor(
    public cartService: ShoppingCartService,
    private translateSrv: TranslateService,
    private operandosSrv: OperandosService,
    private authSrv: AuthenticationService,
    private router: Router,
    private modalSrv: ModalService,
    private sanitazer: DomSanitizer
  ) { }
  @Input() set dataSource(value) {
    this._dataSource = value;
    this.numRowFooter = 0;
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.currentLanguage = this.translateSrv.currentLang;
    this.operandosSrv.operandosGet().subscribe({
      next: (data) => {
        this.operands = [...data];
      },
      error: (error) => console.log(error)
    });
    this.role = this.authSrv.getRoles()[0];
    this.displayedColumnsMock = [];
    this.newData = [];
    this.displayedColumnsMock = this.displayedColumnsMock.concat(this._dataSource.headerValues);
    // add col actions, esto hay que quitarlo cuando este listo el servicio
    this.displayedColumnsMock.push('actions');

    // duplicate columns to groups
    this.displayedColumnsGroup = this.displayedColumnsMock.map((value: string) => 'group_' + value);

    this.dataSourceMock = this.prepareData(this._dataSource.values, 0);
    if (this.detailCaseView) {
      if (
        this.dataSourceMock &&
        this.dataSourceMock.length &&
        this.dataSourceMock[this.dataSourceMock.length - 1][this.dataSourceMock[this.dataSourceMock.length - 1].length - 1].indexOf(
          'cart_finalResult'
        ) >= 0
      ) {
        this.resultFooter = this.dataSourceMock.splice(this.dataSourceMock.length - 2, 2);
        if (this.resultFooter[this.resultFooter.length - 1].length !== this.displayedColumnsMock.length) {
          while (this.resultFooter[this.resultFooter.length - 1].length !== this.displayedColumnsMock.length) {
            this.resultFooter[1].splice(2, 0, '');
          }
        }
      } else {
        if (this.dataSourceMock.length) {
          this.resultFooter = this.dataSourceMock.splice(this.dataSourceMock.length - 1, 1);
          this.resultFooter.push(['', `<div class="cps-0">${this.translateSrv.instant('RATE.FINALRESULT')}</div>`, 'cart_finalResult']);
          while (this.resultFooter[this.resultFooter.length - 1].length !== this.displayedColumnsMock.length) {
            this.resultFooter[1].splice(2, 0, '');
          }
        }
      }
    }

  }

  addEvents() {
    if (this.tableEvent?._elementRef?.nativeElement) {
      this.tableEvent?._elementRef?.nativeElement.querySelectorAll('[\\(click\\)]').forEach((element) => {
        let click = element.getAttribute('(click)');
        if (click.indexOf('redirectRate(\'') !== -1) {
          click = click.replace('redirectRate(\'', '').replace('\')', '');
        }
        element.addEventListener('click', () => {
          this.redirectRate(click);
        });
      });
    }
  }

  linkedRisk(element: any) {
    return element && typeof element === 'object' && element.changingThisBreaksApplicationSecurity.includes('(click)="');
  }

  getResultFooter(header: string, indexHeader: number) {
    if (header === 'actions') {
      this.numRowFooter += 1;
      if (this.numRowFooter === this.resultFooter.length) {
        this.numRowFooter = 0;
      }
      return '';
    } else {
      if (this.resultFooter && this.numRowFooter < this.resultFooter.length) {
        return this.resultFooter[this.numRowFooter][indexHeader];
      }
    }
  }

  ngAfterViewInit() {
    if (this.indexBlink > -1) {
      let row = document.getElementsByClassName('mat-row')[this.indexBlink];
      row.className += ' blink';
      row.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    this.addEvents();
  }

  isParent(index, item): boolean {
    return item?.isParent;
  }

  isDoctorRole() {
    return this.authSrv.checkRolUser(DOCTOR);
  }

  routerLink(element: any) {
    // console.log('Elemento clicado', element);
  }

  sanitizerHtml(value: string) {
    const parser = new DOMParser();
    const document = parser.parseFromString(value, 'text/html');
    return this.sanitazer.bypassSecurityTrustHtml(document.body.outerHTML);
  }

  checkAndTransformLinkData(data: (any)[]) {
    data.forEach((item, index) => {
      if (typeof item === 'object' && item?.changingThisBreaksApplicationSecurity === undefined) {
        if (item && item.label) {
          let label = item.label;
          if (typeof item.label === 'object') {
            label = item.label[this.translateSrv.currentLang];
          }
          let html: string = `<b>${this.translateSrv.instant('RATE.RATE-AS-TEXT')} </b> <a (click)="redirectRate('${item.link}')" >${label}</a>`;
          data[index] = this.sanitizerHtml(html);
        } else {
          if (item && item[this.translateSrv.currentLang]) {
            data[index] = item[this.translateSrv.currentLang];
          } else {
            data[index] = '';
          }
        }
      }
    });
  }


  redirectRate(item) {
    this.calc.emit(true);
    this.router.navigateByUrl('/' + item);

  }
  prepareData(data: any[], level?: number) {
    for (let index = 0; index < data.length; index++) {
      const v = data[index];
      if (v.children?.length > 0) {
        if (!level) {
          v.values[this._dataSource.stickyColsStart - 1] = `<div class="cps-${level}">${v.values[this._dataSource.stickyColsStart - 1]}</div>`;
          this.checkAndTransformLinkData(v.values);
          this.newData.push({
            isParent: !level,
            values: v
          });
        } else {
          v.values[this._dataSource.stickyColsStart - 1] =
            `<div class="cps-${level}">` + this.translateSrv.instant(v.values[this._dataSource.stickyColsStart - 1]) + '</div>';
          this.checkAndTransformLinkData(v.values);
          this.newData.push(v.values.concat(v.id));
          // añadimos al final el id para la accion del carrito hay que quitar esto cuando este el servicio listo. .concat('cart_'+v.id)
        }

        this.prepareData(v.children, level + 1);
        /*  v.children.forEach((element) => {
           this.prepareData(element.values, level ? 0 : level++);
         }); */
      } else {
        v.values[this._dataSource.stickyColsStart - 1] =
          `<div class="cps-${level}">` + this.translateSrv.instant(v.values[this._dataSource.stickyColsStart - 1]) + '</div>';
        this.checkAndTransformLinkData(v.values);
        this.newData.push(v.values.concat('cart_' + v.id));
        // añadimos al final el id para la accion del carrito hay que quitar esto cuando este el servicio listo. .concat('cart_'+v.id)
      }
    }
    return this.newData;
  }

  getNgModel($event: any, id: string, indexHeader: number, data: any[]) {
    for (let index = 0; index < data.length; index++) {
      if (data[index].children) {
        this.getNgModel($event, id, indexHeader, data[index].children);
      } else {
        if ('cart_' + data[index].id === id) {
          data[index].values[indexHeader] = $event.target.value;
          // console.log(data[index].values[indexHeader], $event.target.value);
          this.changeData.emit(this._dataSource);
        }
      }
    }
  }

  addSelectOption(event: MatAutocompleteSelectedEvent, id: string, indexHeader: number, data: any[]) {
    // this.getNgModel({ target: { value: event.option.getLabel() } }, id, indexHeader, data);
    for (let index = 0; index < data.length; index++) {
      if (data[index].children) {
        this.addSelectOption(event, id, indexHeader, data[index].children);
      } else {
        if ('cart_' + data[index].id === id) {
          data[index].values[indexHeader] = data[index].values[indexHeader] + event.option.getLabel();
          this.changeData.emit(this._dataSource);
        }
      }
    }
  }

  isInCart(id: string): boolean {
    return this.cartService.isInCart(id);
  }

  isToCalculate(element: string[]) {
    this._showBC = true;
    return element.some((item) => {
      if (typeof (item) !== 'string') {
        return false;
      }
      if (item.startsWith('<div class=')) {
        return false;
      }
      if (element.join().includes('Metabolic calculator') || element.join().includes('Final result') || element.join().includes('Melanoma calculator')) {
        this._showBC = false;
        return false;

      }
      else {
        if (this.resultTable) {
          return false;
        } else {
          // return item.toLowerCase().includes('calc');
          return this.includesLiteral(item, this.currentLanguage.toUpperCase());
        }
      }
    });
  }

  normalizeText(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  includesLiteral(item: string, lang: string): boolean {
    const literal = this.literals[lang];
    return this.normalizeText(item).includes(this.normalizeText(literal));
  }

  openCalculator(element: string[]) {
    if (element.includes('mel')) {
      this.router.navigate(['melanoma-calculator']);
    } else if (element.includes('met')) {
      this.router.navigate(['metabolic-calculator']);
    } else if (element.includes('bc')) {
      this.router.navigate(['breast-cancer-calculator'])
    } else {
      this.router.navigate(['metabolic-calculator']);
    }
    this.calc.emit(true);
  }

  remove(id: string) {
    this.removeId.emit(id.replace('cart_', ''));
  }

  removeToCart(id: string): void {
    this._showBC = !this._showBC;
    const p = new Product({});
    p.id = id;
    this.cartService.removeItem(p);
  }
  addToCart(id: string): void {
    if (!this.calcDisclaimer) {
      this._showBC = !this._showBC;
      this.dataCard.id = id;
      this.dataCard = this.getTitleDesc(this.dataCard.id);
      this.cartService.addItem(this.dataCard);
      // console.log(this.cartService.numItemsCart());
    } else {
      // console.log('Mostramos modal');
      this.modalSrv.openModalInfo(
        '',
        'MODAL-WARNING.MODAL-INFO.METCAL-DISCLAIMER',
      );
    }
  }

  disabledActions() {
    /* return !this.caseSrv.hasInCourse(); */
    return false;
  }

  getTitleDescById(id: string, data: any[], dataCard: any) {
    for (const m of data) {
      if (id?.includes(m.id)) {
        if (dataCard.parentId === id) {
          dataCard.name = this.title ? this.title : id.split('_')[0];
          break;
        } else {
          dataCard.description = dataCard?.description
            ? this.removeHtml(m.values[this._dataSource.stickyColsStart - 1]) + ' > ' + dataCard.description
            : this.removeHtml(m.values[this._dataSource.stickyColsStart - 1]);
          this.getTitleDescById(dataCard.parentId, this._dataSource['values'], dataCard);
        }
      } else if (m.children?.length > 0) {
        dataCard.parentId = m.id;
        this.getTitleDescById(id, m.children, dataCard);
      }
    }
    return null;
  }

  removeHtml(html: string) {
    const firstRegex = new RegExp(/<div class="cps-\d">/g);
    const secongRegex = new RegExp(/<\/div>/g);
    return html.replace(firstRegex, '').replace(secongRegex, '');
  }

  getTitleDesc(id: string): Product {
    let dataCard = {
      id: id,
      parentId: '',
      description: '',
      name: ''
    };
    this.getTitleDescById(id, this._dataSource['values'], dataCard);
    delete dataCard.parentId;
    return new Product(dataCard);
  }
}
